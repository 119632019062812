.gridContainerFull {
    grid-template-areas: "menu mainArea properties";
    grid-template-columns: 200px 1fr 200px;
    /* 80px 270px 1fr 220px */
  }
  .gridContainerleft {
    grid-template-areas: "menu mainArea properties";
    grid-template-columns: 200px 1fr 200px;
    
  }
  .gridContainerRight {
    grid-template-areas: "menu mainArea properties";
    grid-template-columns: 200px 1fr 200px;
    /* 80px 270px 1fr 220px */
  }
  .gridContainer {
    grid-template-areas: "menu mainArea properties";
    grid-template-columns: 220px 1fr 230px;
  }
  
  input[type="color"] {
    -webkit-appearance: none;
    border: none;
    outline: none;
    width: 20px;
    height: 20px;
  }
  input[type="color"]::-webkit-color-swatch-wrapper {
    padding: 0;
  }
  input[type="color"]::-webkit-color-swatch {
    border: none;
  }
  
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
  }
  
  /*
   *  scroll-bar
   */
  
  .scroll-bar::-webkit-scrollbar-track {
    /* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); */
    border-radius: 10px;
    /* background-color: #F5F5F5; */
  }
  
  .scroll-bar::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    /* background-color: #F5F5F5; */
  }
  
  .scroll-bar::-webkit-scrollbar-thumb {
    border-radius: 10px;
    /* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3); */
    background-color: #e2e8f0;
  }
  
 /* component border area class*/
 .fielddragCss {
  position: relative; 
   /* min-height: 40px;  */
  border: 2.6px dashed #cbd5e1; 
  /* border: 1px dashed #474747;  */
  padding: 20px !important; 
   transition: all 0.5s; 
 }
.fielddragCss:hover {
  /* border: 1px dashed #94a3b8; */
  /* padding: 6px !important; */
  cursor: pointer;
  transition: all 0.5s;
  border: 2.6px dashed #000;
}
/* component border area class child components*/
.fielddragChild {
  position: relative;
  border: 1.6px dashed #9e9e9e6b;
  /* padding: 10px !important; */
}
.fielddragChild:hover {
  border: 1.6px solid #3f51b575;
  /* border: 1.6px solid red; */
  cursor: pointer;
}
/* Component name shower class */
.fielddragCss:hover > .componentNameOnOver {
  z-index: 1 !important;
}
.componentNameOnOver {
  background-color: #3f51b5;
  position: absolute;
  top: -26px;
  z-index: -1;
  padding: 1px 10px;
  left: -1px;
  color: #fff;
}
/* On Over the component drop area */
.mystyle {
  /* background-color: #3f51b575 !important; */
  background-color: #e2e8f0 !important;
  border: 2.5px dashed #bac1cb;
  /* border:5px solid black; */
  transition: all 0.4s;
}
  .mystyle > .componentNameOnOver {
    border:20px solid red;
    z-index: 1 !important;
  }
  
  .dragged-over {
    /* background-color: #3f51b575 !important; */
    background-color: lightgray !important;
  }
  
  .react-loading-style{
    display: flex;
  }