.vivify {
  -webkit-animation-duration: 0.8s;
  -webkit-animation-fill-mode: both;
  animation-duration: 0.8s;
  animation-fill-mode: both;
}
.vivifySmall {
  -webkit-animation-duration: 0.6s;
  -webkit-animation-fill-mode: both;
  animation-duration: 0.6s;
  animation-fill-mode: both;
}
.infinite {
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}
.pulsate {
  -webkit-animation-name: pulsate;
  animation-name: pulsate;
  -webkit-transform-origin: center;
  transform-origin: center;
}
@keyframes pulsate {
  0% {
    -webkit-animation-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
    animation-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  50% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9);
  }
  100% {
    -webkit-animation-timing-function: cubic-bezier(0.895, 0.03, 0.685, 0.22);
    animation-timing-function: cubic-bezier(0.895, 0.03, 0.685, 0.22);
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
.blink {
  -webkit-animation-name: blink;
  animation-name: blink;
  -webkit-animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
}
@keyframes blink {
  0% {
    opacity: 1;
  }
  25% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  75% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.hitLeft {
  -webkit-animation-name: hitLeft;
  animation-name: hitLeft;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
@keyframes hitLeft {
  0% {
    -webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
    animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  40% {
    -webkit-animation-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715);
    animation-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715);
    -webkit-transform: translateX(20px) rotate(4deg);
    transform: translateX(20px) rotate(4deg);
  }
}
.hitRight {
  -webkit-animation-name: hitRight;
  animation-name: hitRight;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
@keyframes hitRight {
  0% {
    -webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
    animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  40% {
    -webkit-animation-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715);
    animation-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715);
    -webkit-transform: translateX(-20px) rotate(-4deg);
    transform: translateX(-20px) rotate(-4deg);
  }
}
.shake {
  -webkit-animation-name: shake;
  animation-name: shake;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
}
@keyframes shake {
  10% {
    -webkit-transform: translateX(-10px);
    transform: translateX(-10px);
  }
  20% {
    -webkit-transform: translateX(10px);
    transform: translateX(10px);
  }
  30% {
    -webkit-transform: translateX(-10px);
    transform: translateX(-10px);
  }
  40% {
    -webkit-transform: translateX(10px);
    transform: translateX(10px);
  }
  50% {
    -webkit-transform: translateX(-10px);
    transform: translateX(-10px);
  }
  60% {
    -webkit-transform: translateX(10px);
    transform: translateX(10px);
  }
  70% {
    -webkit-transform: translateX(-10px);
    transform: translateX(-10px);
  }
  80% {
    -webkit-transform: translateX(10px);
    transform: translateX(10px);
  }
  90% {
    -webkit-transform: translateX(-10px);
    transform: translateX(-10px);
  }
}
.ball {
  -webkit-animation-name: ball;
  animation-name: ball;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
}
@keyframes ball {
  0% {
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  20% {
    -webkit-animation-timing-function: cubic-bezier(0.42, 0, 1, 1);
    animation-timing-function: cubic-bezier(0.42, 0, 1, 1);
    -webkit-transform: translate3d(0, -100px, 0);
    transform: translate3d(0, -100px, 0);
  }
  40% {
    -webkit-animation-timing-function: cubic-bezier(0, 0, 0.58, 1);
    animation-timing-function: cubic-bezier(0, 0, 0.58, 1);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  60% {
    -webkit-animation-timing-function: cubic-bezier(0.42, 0, 1, 1);
    animation-timing-function: cubic-bezier(0.42, 0, 1, 1);
    -webkit-transform: translate3d(0, -30px, 0);
    transform: translate3d(0, -30px, 0);
  }
  75% {
    -webkit-animation-timing-function: cubic-bezier(0, 0, 0.58, 1);
    animation-timing-function: cubic-bezier(0, 0, 0.58, 1);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  85% {
    -webkit-animation-timing-function: cubic-bezier(0.42, 0, 1, 1);
    animation-timing-function: cubic-bezier(0.42, 0, 1, 1);
    -webkit-transform: translate3d(0, -15px, 0);
    transform: translate3d(0, -15px, 0);
  }
  100% {
    -webkit-animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
.pullUp {
  -webkit-animation-name: pullUp;
  animation-name: pullUp;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
@keyframes pullUp {
  0% {
    -webkit-transform: scale3d(0, 0, 0);
    transform: scale3d(0, 0, 0);
  }
  30% {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transform: scale3d(1, 0, 1);
    transform: scale3d(1, 0, 1);
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
  }
  100% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
.pullDown {
  -webkit-animation-name: pullDown;
  animation-name: pullDown;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
@keyframes pullDown {
  0% {
    -webkit-transform: scale3d(0, 0, 0);
    transform: scale3d(0, 0, 0);
  }
  50% {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transform: scale3d(1, 0, 1);
    transform: scale3d(1, 0, 1);
    -webkit-transform-origin: center top;
    transform-origin: center top;
  }
  100% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
.pullLeft {
  -webkit-animation-name: pullLeft;
  animation-name: pullLeft;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
@keyframes pullLeft {
  0% {
    -webkit-transform: scale3d(0, 1, 1);
    transform: scale3d(0, 1, 1);
  }
  50% {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transform: scale3d(0, 1, 1);
    transform: scale3d(0, 1, 1);
    -webkit-transform-origin: center left;
    transform-origin: center left;
  }
  100% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
.pullRight {
  -webkit-animation-name: pullRight;
  animation-name: pullRight;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
@keyframes pullRight {
  0% {
    -webkit-transform: scale3d(0, 1, 1);
    transform: scale3d(0, 1, 1);
  }
  50% {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transform: scale3d(0, 1, 1);
    transform: scale3d(0, 1, 1);
    -webkit-transform-origin: center right;
    transform-origin: center right;
  }
  100% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
.jumpInLeft {
  -webkit-animation-name: jumpInLeft;
  animation-name: jumpInLeft;
  -webkit-animation-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  animation-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
@keyframes jumpInLeft {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.5, 0.5, 0.5) rotate(-90deg);
    transform: scale3d(0.5, 0.5, 0.5) rotate(-90deg);
  }
  50% {
    opacity: 1;
    -webkit-transform: scale3d(1.2, 1.2, 1.2) rotate(10deg);
    transform: scale3d(1.2, 1.2, 1.2) rotate(10deg);
  }
  70% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9) rotate(-5deg);
    transform: scale3d(0.9, 0.9, 0.9) rotate(-5deg);
  }
  90% {
    -webkit-transform: scale3d(1, 1, 1) rotate(0deg);
    transform: scale3d(1, 1, 1) rotate(0deg);
  }
}
.jumpInRight {
  -webkit-animation-name: jumpInRight;
  animation-name: jumpInRight;
  -webkit-animation-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  animation-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
@keyframes jumpInRight {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.5, 0.5, 0.5) rotate(90deg);
    transform: scale3d(0.5, 0.5, 0.5) rotate(90deg);
  }
  50% {
    opacity: 1;
    -webkit-transform: scale3d(1.2, 1.2, 1.2) rotate(-10deg);
    transform: scale3d(1.2, 1.2, 1.2) rotate(-10deg);
  }
  70% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9) rotate(5deg);
    transform: scale3d(0.9, 0.9, 0.9) rotate(5deg);
  }
  90% {
    -webkit-transform: scale3d(1, 1, 1) rotate(0deg);
    transform: scale3d(1, 1, 1) rotate(0deg);
  }
}
.jumpOutRight {
  -webkit-animation-name: jumpOutRight;
  animation-name: jumpOutRight;
  -webkit-animation-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  animation-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
@keyframes jumpOutRight {
  0% {
    -webkit-transform: scale3d(1, 1, 1) rotate(0deg);
    transform: scale3d(1, 1, 1) rotate(0deg);
  }
  30% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9) rotate(5deg);
    transform: scale3d(0.9, 0.9, 0.9) rotate(5deg);
  }
  70% {
    opacity: 1;
    -webkit-transform: scale3d(1.2, 1.2, 1.2) rotate(-10deg);
    transform: scale3d(1.2, 1.2, 1.2) rotate(-10deg);
  }
  100% {
    opacity: 0;
    -webkit-transform: scale3d(0.5, 0.5, 0.5) rotate(90deg);
    transform: scale3d(0.5, 0.5, 0.5) rotate(90deg);
  }
}
.jumpOutLeft {
  -webkit-animation-name: jumpOutLeft;
  animation-name: jumpOutLeft;
  -webkit-animation-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  animation-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
@keyframes jumpOutLeft {
  0% {
    -webkit-transform: scale3d(1, 1, 1) rotate(0deg);
    transform: scale3d(1, 1, 1) rotate(0deg);
  }
  30% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9) rotate(-5deg);
    transform: scale3d(0.9, 0.9, 0.9) rotate(-5deg);
  }
  70% {
    opacity: 1;
    -webkit-transform: scale3d(1.2, 1.2, 1.2) rotate(10deg);
    transform: scale3d(1.2, 1.2, 1.2) rotate(10deg);
  }
  100% {
    opacity: 0;
    -webkit-transform: scale3d(0.5, 0.5, 0.5) rotate(-90deg);
    transform: scale3d(0.5, 0.5, 0.5) rotate(-90deg);
  }
}
.rollInLeft {
  -webkit-animation-name: rollInLeft;
  animation-name: rollInLeft;
  -webkit-animation-duration: 0.9s;
  animation-duration: 0.9s;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
@keyframes rollInLeft {
  0% {
    -webkit-animation-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
    animation-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
    -webkit-transform: translateX(-400px) rotate(445deg);
    transform: translateX(-400px) rotate(445deg);
    opacity: 0;
  }
  30% {
    opacity: 1;
  }
  50% {
    -webkit-transform: translateX(20px) rotate(20deg);
    transform: translateX(20px) rotate(20deg);
  }
  100% {
    -webkit-animation-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
    animation-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
    -webkit-transform: translateX(0) rotate(0deg);
    transform: translateX(0) rotate(0deg);
  }
}
.rollInRight {
  -webkit-animation-name: rollInRight;
  animation-name: rollInRight;
  -webkit-animation-duration: 0.9s;
  animation-duration: 0.9s;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
@keyframes rollInRight {
  0% {
    -webkit-animation-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
    animation-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
    -webkit-transform: translateX(400px) rotate(-445deg);
    transform: translateX(400px) rotate(-445deg);
    opacity: 0;
  }
  30% {
    opacity: 1;
  }
  50% {
    -webkit-transform: translateX(-20px) rotate(-20deg);
    transform: translateX(-20px) rotate(-20deg);
  }
  100% {
    -webkit-animation-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
    animation-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
    -webkit-transform: translateX(0) rotate(0deg);
    transform: translateX(0) rotate(0deg);
  }
}
.rollInTop {
  -webkit-animation-name: rollInTop;
  animation-name: rollInTop;
  -webkit-animation-duration: 0.9s;
  animation-duration: 0.9s;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
@keyframes rollInTop {
  0% {
    -webkit-animation-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
    animation-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
    -webkit-transform: translateY(-400px) rotate(-445deg);
    transform: translateY(-400px) rotate(-445deg);
    opacity: 0;
  }
  30% {
    opacity: 1;
  }
  50% {
    -webkit-transform: translateY(20px) rotate(-20deg);
    transform: translateY(20px) rotate(-20deg);
  }
  100% {
    -webkit-animation-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
    animation-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
    -webkit-transform: translateY(0) rotate(0deg);
    transform: translateY(0) rotate(0deg);
  }
}
.rollInBottom {
  -webkit-animation-name: rollInBottom;
  animation-name: rollInBottom;
  -webkit-animation-duration: 0.9s;
  animation-duration: 0.9s;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
@keyframes rollInBottom {
  0% {
    -webkit-animation-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
    animation-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
    -webkit-transform: translateY(400px) rotate(-445deg);
    transform: translateY(400px) rotate(-445deg);
    opacity: 0;
  }
  30% {
    opacity: 1;
  }
  50% {
    -webkit-transform: translateY(-20px) rotate(-20deg);
    transform: translateY(-20px) rotate(-20deg);
  }
  100% {
    -webkit-animation-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
    animation-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
    -webkit-transform: translateY(0) rotate(0deg);
    transform: translateY(0) rotate(0deg);
  }
}
.rollOutLeft {
  -webkit-animation-name: rollOutLeft;
  animation-name: rollOutLeft;
  -webkit-animation-duration: 0.9s;
  animation-duration: 0.9s;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
@keyframes rollOutLeft {
  0% {
    opacity: 1;
    -webkit-animation-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
    animation-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
    -webkit-transform: translateX(0) rotate(0deg);
    transform: translateX(0) rotate(0deg);
  }
  40% {
    opacity: 1;
    -webkit-transform: translateX(20px) rotate(-20deg);
    transform: translateX(20px) rotate(-20deg);
  }
  100% {
    opacity: 0;
    -webkit-animation-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
    animation-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
    -webkit-transform: translateX(-400px) rotate(-445deg);
    transform: translateX(-400px) rotate(-445deg);
  }
}
.rollOutRight {
  -webkit-animation-name: rollOutRight;
  animation-name: rollOutRight;
  -webkit-animation-duration: 0.9s;
  animation-duration: 0.9s;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
@keyframes rollOutRight {
  0% {
    opacity: 1;
    -webkit-animation-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
    animation-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
    -webkit-transform: translateX(0) rotate(0deg);
    transform: translateX(0) rotate(0deg);
  }
  40% {
    opacity: 1;
    -webkit-transform: translateX(-20px) rotate(20deg);
    transform: translateX(-20px) rotate(20deg);
  }
  100% {
    opacity: 0;
    -webkit-animation-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
    animation-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
    -webkit-transform: translateX(400px) rotate(445deg);
    transform: translateX(400px) rotate(445deg);
  }
}
.rollOutTop {
  -webkit-animation-name: rollOutTop;
  animation-name: rollOutTop;
  -webkit-animation-duration: 0.9s;
  animation-duration: 0.9s;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
@keyframes rollOutTop {
  0% {
    opacity: 1;
    -webkit-animation-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
    animation-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
    -webkit-transform: translateY(0) rotate(0deg);
    transform: translateY(0) rotate(0deg);
  }
  40% {
    opacity: 1;
    -webkit-transform: translateY(20px) rotate(20deg);
    transform: translateY(20px) rotate(20deg);
  }
  100% {
    opacity: 0;
    -webkit-animation-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
    animation-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
    -webkit-transform: translateY(-400px) rotate(445deg);
    transform: translateY(-400px) rotate(445deg);
  }
}
.rollOutBottom {
  -webkit-animation-name: rollOutBottom;
  animation-name: rollOutBottom;
  -webkit-animation-duration: 0.9s;
  animation-duration: 0.9s;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
@keyframes rollOutBottom {
  0% {
    opacity: 1;
    -webkit-animation-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
    animation-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
    -webkit-transform: translateY(0) rotate(0deg);
    transform: translateY(0) rotate(0deg);
  }
  40% {
    opacity: 1;
    -webkit-transform: translateY(-20px) rotate(-20deg);
    transform: translateY(-20px) rotate(-20deg);
  }
  100% {
    opacity: 0;
    -webkit-animation-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
    animation-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
    -webkit-transform: translateY(400px) rotate(-445deg);
    transform: translateY(400px) rotate(-445deg);
  }
}
.popIn {
  -webkit-animation-name: popIn;
  animation-name: popIn;
}
@keyframes popIn {
  0% {
    -webkit-transform: scale3d(0, 0, 0);
    transform: scale3d(0, 0, 0);
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  40% {
    -webkit-animation-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715);
    animation-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715);
    -webkit-transform: scale3d(1.08, 1.08, 1.08);
    transform: scale3d(1.08, 1.08, 1.08);
  }
  60% {
    -webkit-animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  80% {
    -webkit-animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    -webkit-transform: scale3d(1.03, 1.03, 1.03);
    transform: scale3d(1.03, 1.03, 1.03);
  }
  100% {
    -webkit-animation-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
    animation-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
.popInLeft {
  -webkit-animation-name: popInLeft;
  animation-name: popInLeft;
}
@keyframes popInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-200px, 0, 0) scale3d(0.1, 0.1, 0.1);
    transform: translate3d(-200px, 0, 0) scale3d(0.1, 0.1, 0.1);
  }
  40% {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0) scale3d(1.08, 1.08, 1.08);
    transform: translate3d(0, 0, 0) scale3d(1.08, 1.08, 1.08);
    -webkit-animation-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715);
    animation-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715);
  }
  60% {
    -webkit-animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  80% {
    -webkit-animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    -webkit-transform: scale3d(1.03, 1.03, 1.03);
    transform: scale3d(1.03, 1.03, 1.03);
  }
  100% {
    -webkit-animation-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
    animation-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
.popInRight {
  -webkit-animation-name: popInRight;
  animation-name: popInRight;
}
@keyframes popInRight {
  0% {
    -webkit-transform: translate3d(200px, 0, 0) scale3d(0.1, 0.1, 0.1);
    transform: translate3d(200px, 0, 0) scale3d(0.1, 0.1, 0.1);
    opacity: 0;
  }
  40% {
    opacity: 1;
    -webkit-animation-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715);
    animation-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715);
    -webkit-transform: translate3d(0, 0, 0) scale3d(1.08, 1.08, 1.08);
    transform: translate3d(0, 0, 0) scale3d(1.08, 1.08, 1.08);
  }
  60% {
    -webkit-animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  80% {
    -webkit-animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    -webkit-transform: scale3d(1.03, 1.03, 1.03);
    transform: scale3d(1.03, 1.03, 1.03);
  }
  100% {
    -webkit-animation-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
    animation-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
.popInTop {
  -webkit-animation-name: popInTop;
  animation-name: popInTop;
}
@keyframes popInTop {
  0% {
    -webkit-transform: translate3d(0, -200px, 0) scale3d(0.1, 0.1, 0.1);
    transform: translate3d(0, -200px, 0) scale3d(0.1, 0.1, 0.1);
    opacity: 0;
  }
  40% {
    opacity: 1;
    -webkit-animation-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715);
    animation-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715);
    -webkit-transform: translate3d(0, 0, 0) scale3d(1.08, 1.08, 1.08);
    transform: translate3d(0, 0, 0) scale3d(1.08, 1.08, 1.08);
  }
  60% {
    -webkit-animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    -webkit-transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
    transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
  }
  80% {
    -webkit-animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    -webkit-transform: translate3d(0, 0, 0) scale3d(1.03, 1.03, 1.03);
    transform: translate3d(0, 0, 0) scale3d(1.03, 1.03, 1.03);
  }
  100% {
    -webkit-animation-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
    animation-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
    -webkit-transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
    transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
  }
}
.popInBottom {
  -webkit-animation-name: popInBottom;
  animation-name: popInBottom;
}
@keyframes popInBottom {
  0% {
    -webkit-transform: translate3d(0, 200px, 0) scale3d(0.1, 0.1, 0.1);
    transform: translate3d(0, 200px, 0) scale3d(0.1, 0.1, 0.1);
    opacity: 0;
  }
  40% {
    opacity: 1;
    -webkit-animation-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715);
    animation-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715);
    -webkit-transform: translate3d(0, 0, 0) scale3d(1.08, 1.08, 1.08);
    transform: translate3d(0, 0, 0) scale3d(1.08, 1.08, 1.08);
  }
  60% {
    -webkit-animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    -webkit-transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
    transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
  }
  80% {
    -webkit-animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    -webkit-transform: translate3d(0, 0, 0) scale3d(1.03, 1.03, 1.03);
    transform: translate3d(0, 0, 0) scale3d(1.03, 1.03, 1.03);
  }
  100% {
    -webkit-animation-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
    animation-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
    -webkit-transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
    transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
  }
}
.popOut {
  -webkit-animation-name: popOut;
  animation-name: popOut;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
}
@keyframes popOut {
  0% {
    -webkit-animation-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
    animation-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  60% {
    -webkit-animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    -webkit-transform: scale3d(1.08, 1.08, 1.08);
    transform: scale3d(1.08, 1.08, 1.08);
  }
  80% {
    opacity: 1;
    -webkit-animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  100% {
    opacity: 0;
    -webkit-animation-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715);
    animation-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715);
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
}
.popOutLeft {
  -webkit-animation-name: popOutLeft;
  animation-name: popOutLeft;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
}
@keyframes popOutLeft {
  0% {
    -webkit-animation-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
    animation-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
    -webkit-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
    transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
    -webkit-transform-origin: left center;
    transform-origin: left center;
  }
  40% {
    opacity: 1;
    -webkit-animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    -webkit-transform: scale3d(1.08, 1.08, 1.08) translate3d(0, 0, 0);
    transform: scale3d(1.08, 1.08, 1.08) translate3d(0, 0, 0);
  }
  100% {
    opacity: 0;
    -webkit-animation-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715);
    animation-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715);
    -webkit-transform: scale3d(0.5, 0.5, 0.5) translate3d(-200px, 0, 0);
    transform: scale3d(0.5, 0.5, 0.5) translate3d(-200px, 0, 0);
  }
}
.popOutRight {
  -webkit-animation-name: popOutRight;
  animation-name: popOutRight;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
}
@keyframes popOutRight {
  0% {
    -webkit-animation-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
    animation-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
    -webkit-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
    transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
    -webkit-transform-origin: right center;
    transform-origin: right center;
  }
  40% {
    opacity: 1;
    -webkit-animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    -webkit-transform: scale3d(1.08, 1.08, 1.08) translate3d(0, 0, 0);
    transform: scale3d(1.08, 1.08, 1.08) translate3d(0, 0, 0);
  }
  100% {
    opacity: 0;
    -webkit-animation-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715);
    animation-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715);
    -webkit-transform: scale3d(0.5, 0.5, 0.5) translate3d(200px, 0, 0);
    transform: scale3d(0.5, 0.5, 0.5) translate3d(200px, 0, 0);
  }
}
.popOutTop {
  -webkit-animation-name: popOutTop;
  animation-name: popOutTop;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
}
@keyframes popOutTop {
  0% {
    -webkit-animation-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
    animation-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
    -webkit-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
    transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
    -webkit-transform-origin: top center;
    transform-origin: top center;
  }
  40% {
    opacity: 1;
    -webkit-animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    -webkit-transform: scale3d(1.08, 1.08, 1.08) translate3d(0, 0, 0);
    transform: scale3d(1.08, 1.08, 1.08) translate3d(0, 0, 0);
  }
  100% {
    opacity: 0;
    -webkit-animation-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715);
    animation-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715);
    -webkit-transform: scale3d(0.5, 0.5, 0.5) translate3d(0, -400px, 0);
    transform: scale3d(0.5, 0.5, 0.5) translate3d(0, -400px, 0);
  }
}
.popOutBottom {
  -webkit-animation-name: popOutBottom;
  animation-name: popOutBottom;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
}
@keyframes popOutBottom {
  0% {
    -webkit-animation-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
    animation-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
    -webkit-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
    transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
    -webkit-transform-origin: bottom center;
    transform-origin: bottom center;
  }
  40% {
    opacity: 1;
    -webkit-animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    -webkit-transform: scale3d(1.08, 1.08, 1.08) translate3d(0, 0, 0);
    transform: scale3d(1.08, 1.08, 1.08) translate3d(0, 0, 0);
  }
  100% {
    opacity: 0;
    -webkit-animation-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715);
    animation-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715);
    -webkit-transform: scale3d(0.5, 0.5, 0.5) translate3d(0, 400px, 0);
    transform: scale3d(0.5, 0.5, 0.5) translate3d(0, 400px, 0);
  }
}
.flip {
  -webkit-animation-name: flip;
  animation-name: flip;
}
@keyframes flip {
  from {
    -webkit-transform: perspective(400px) rotateY(-360deg) scale3d(1, 1, 1);
    transform: perspective(400px) rotateY(-360deg) scale3d(1, 1, 1);
    -webkit-animation-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
    animation-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  }
  50% {
    -webkit-transform: perspective(400px) rotateY(-180deg)
      scale3d(1.2, 1.2, 1.2);
    transform: perspective(400px) rotateY(-180deg) scale3d(1.2, 1.2, 1.2);
    -webkit-animation-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
    animation-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  }
  to {
    -webkit-transform: perspective(400px) rotateY(0deg) scale3d(1, 1, 1);
    transform: perspective(400px) rotateY(0deg) scale3d(1, 1, 1);
    -webkit-animation-timing-function: cubic-bezier(0.95, 0.05, 0.795, 0.035);
    animation-timing-function: cubic-bezier(0.95, 0.05, 0.795, 0.035);
  }
}
.flipInX {
  -webkit-animation-name: flipInX;
  animation-name: flipInX;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
}
@keyframes flipInX {
  from {
    opacity: 0;
    -webkit-transform: perspective(400px) rotateX(90deg) scale3d(1.2, 1.2, 1.2);
    transform: perspective(400px) rotateX(90deg) scale3d(1.2, 1.2, 1.2);
  }
  50% {
    opacity: 1;
    -webkit-animation-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
    animation-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
    -webkit-transform: perspective(400px) rotateX(-20deg) scale3d(1, 1, 1);
    transform: perspective(400px) rotateX(-20deg) scale3d(1, 1, 1);
  }
  to {
    -webkit-transform: perspective(400px) rotateX(0deg) scale3d(1, 1, 1);
    transform: perspective(400px) rotateX(0deg) scale3d(1, 1, 1);
  }
}
.flipInY {
  -webkit-animation-name: flipInY;
  animation-name: flipInY;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
}
@keyframes flipInY {
  from {
    opacity: 0;
    -webkit-transform: perspective(400px) rotateY(90deg) scale3d(1.2, 1.2, 1.2);
    transform: perspective(400px) rotateY(90deg) scale3d(1.2, 1.2, 1.2);
  }
  50% {
    opacity: 1;
    -webkit-animation-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
    animation-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
    -webkit-transform: perspective(400px) rotateY(-20deg) scale3d(1, 1, 1);
    transform: perspective(400px) rotateY(-20deg) scale3d(1, 1, 1);
  }
  to {
    -webkit-transform: perspective(400px) rotateY(0deg) scale3d(1, 1, 1);
    transform: perspective(400px) rotateY(0deg) scale3d(1, 1, 1);
  }
}
.flipOutX {
  -webkit-animation-name: flipOutX;
  animation-name: flipOutX;
}
@keyframes flipOutX {
  from {
    -webkit-transform: perspective(400px) rotateX(0deg) scale3d(1, 1, 1);
    transform: perspective(400px) rotateX(0deg) scale3d(1, 1, 1);
  }
  60% {
    opacity: 1;
    -webkit-animation-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
    animation-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
    -webkit-transform: perspective(400px) rotateX(-30deg) scale3d(1, 1, 1);
    transform: perspective(400px) rotateX(-30deg) scale3d(1, 1, 1);
  }
  to {
    opacity: 0;
    -webkit-transform: perspective(400px) rotateX(90deg) scale3d(1.2, 1.2, 1.2);
    transform: perspective(400px) rotateX(90deg) scale3d(1.2, 1.2, 1.2);
  }
}
.flipOutY {
  -webkit-animation-name: flipOutY;
  animation-name: flipOutY;
}
@keyframes flipOutY {
  from {
    -webkit-transform: perspective(400px) rotateY(0deg) scale3d(1, 1, 1);
    transform: perspective(400px) rotateY(0deg) scale3d(1, 1, 1);
  }
  60% {
    opacity: 1;
    -webkit-animation-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
    animation-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
    -webkit-transform: perspective(400px) rotateY(-30deg) scale3d(1, 1, 1);
    transform: perspective(400px) rotateY(-30deg) scale3d(1, 1, 1);
  }
  to {
    opacity: 0;
    -webkit-transform: perspective(400px) rotateY(90deg) scale3d(1.2, 1.2, 1.2);
    transform: perspective(400px) rotateY(90deg) scale3d(1.2, 1.2, 1.2);
  }
}
.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  -webkit-animation-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
  animation-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
  -webkit-animation-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
  animation-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
}
@keyframes fadeInRight {
  0% {
    -webkit-transform: translate3d(400px, 0, 0);
    transform: translate3d(400px, 0, 0);
    opacity: 0;
  }
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
  -webkit-animation-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
  animation-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
}
@keyframes fadeInLeft {
  0% {
    -webkit-transform: translate3d(-400px, 0, 0);
    transform: translate3d(-400px, 0, 0);
    opacity: 0;
  }
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
.fadeInTop {
  -webkit-animation-name: fadeInTop;
  animation-name: fadeInTop;
  -webkit-animation-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
  animation-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
}
@keyframes fadeInTop {
  0% {
    -webkit-transform: translate3d(0, -400px, 0);
    transform: translate3d(0, -400px, 0);
    opacity: 0;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
.fadeInBottom {
  -webkit-animation-name: fadeInBottom;
  animation-name: fadeInBottom;
  -webkit-animation-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
  animation-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
}
@keyframes fadeInBottom {
  0% {
    -webkit-transform: translate3d(0, 400px, 0);
    transform: translate3d(0, 400px, 0);
    opacity: 0;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
.fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
  -webkit-animation-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
  animation-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.fadeOutLeft {
  -webkit-animation-name: fadeOutLeft;
  animation-name: fadeOutLeft;
  -webkit-animation-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
  animation-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
}
@keyframes fadeOutLeft {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translate3d(-400px, 0, 0);
    transform: translate3d(-400px, 0, 0);
    opacity: 0;
  }
}
.fadeOutRight {
  -webkit-animation-name: fadeOutRight;
  animation-name: fadeOutRight;
  -webkit-animation-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
  animation-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
}
@keyframes fadeOutRight {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translate3d(400px, 0, 0);
    transform: translate3d(400px, 0, 0);
    opacity: 0;
  }
}
.fadeOutTop {
  -webkit-animation-name: fadeOutTop;
  animation-name: fadeOutTop;
  -webkit-animation-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
  animation-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
}
@keyframes fadeOutTop {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
  40% {
    opacity: 0.2;
  }
  100% {
    -webkit-transform: translate3d(0, -300px, 0);
    transform: translate3d(0, -300px, 0);
    opacity: 0;
  }
}
.fadeOutBottom {
  -webkit-animation-name: fadeOutBottom;
  animation-name: fadeOutBottom;
  -webkit-animation-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
  animation-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
}
@keyframes fadeOutBottom {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
  40% {
    opacity: 0.2;
  }
  100% {
    -webkit-transform: translate3d(0, 300px, 0);
    transform: translate3d(0, 300px, 0);
    opacity: 0;
  }
}
.driveInLeft {
  -webkit-animation-name: driveInLeft;
  animation-name: driveInLeft;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
}
@keyframes driveInLeft {
  0% {
    opacity: 0;
    -webkit-animation-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
    animation-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
    -webkit-transform: translate3d(-400px, 0, 0);
    transform: translate3d(-400px, 0, 0);
  }
  30% {
    opacity: 1;
  }
  60% {
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    -webkit-transform: translate3d(30px, 0, 0);
    transform: translate3d(30px, 0, 0);
  }
  100% {
    -webkit-animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
    animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
.driveInRight {
  -webkit-animation-name: driveInRight;
  animation-name: driveInRight;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
}
@keyframes driveInRight {
  0% {
    opacity: 0;
    -webkit-animation-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
    animation-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
    -webkit-transform: translate3d(400px, 0, 0);
    transform: translate3d(400px, 0, 0);
  }
  30% {
    opacity: 1;
  }
  60% {
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    -webkit-transform: translate3d(-30px, 0, 0);
    transform: translate3d(-30px, 0, 0);
  }
  100% {
    -webkit-animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
    animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
.driveInTop {
  -webkit-animation-name: driveInTop;
  animation-name: driveInTop;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
}
@keyframes driveInTop {
  0% {
    opacity: 0;
    -webkit-animation-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
    animation-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
    -webkit-transform: translate3d(0, -400px, 0);
    transform: translate3d(0, -400px, 0);
  }
  30% {
    opacity: 1;
  }
  60% {
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    -webkit-transform: translate3d(0, 30px, 0);
    transform: translate3d(0, 30px, 0);
  }
  100% {
    -webkit-animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
    animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
.driveInBottom {
  -webkit-animation-name: driveInBottom;
  animation-name: driveInBottom;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
}
@keyframes driveInBottom {
  0% {
    opacity: 0;
    -webkit-animation-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
    animation-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
    -webkit-transform: translate3d(0, 300px, 0);
    transform: translate3d(0, 300px, 0);
  }
  30% {
    opacity: 1;
  }
  60% {
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    -webkit-transform: translate3d(0, -30px, 0);
    transform: translate3d(0, -30px, 0);
  }
  100% {
    -webkit-animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
    animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
.driveOutLeft {
  -webkit-animation-name: driveOutLeft;
  animation-name: driveOutLeft;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
}
@keyframes driveOutLeft {
  0% {
    -webkit-animation-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
    animation-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  30% {
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    -webkit-transform: translate3d(30px, 0, 0);
    transform: translate3d(30px, 0, 0);
  }
  60% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    -webkit-animation-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
    animation-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
    -webkit-transform: translate3d(-400px, 0, 0);
    transform: translate3d(-400px, 0, 0);
  }
}
.driveOutRight {
  -webkit-animation-name: driveOutRight;
  animation-name: driveOutRight;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
}
@keyframes driveOutRight {
  0% {
    -webkit-animation-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
    animation-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  30% {
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    -webkit-transform: translate3d(-30px, 0, 0);
    transform: translate3d(-30px, 0, 0);
  }
  60% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    -webkit-animation-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
    animation-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
    -webkit-transform: translate3d(400px, 0, 0);
    transform: translate3d(400px, 0, 0);
  }
}
.driveOutTop {
  -webkit-animation-name: driveOutTop;
  animation-name: driveOutTop;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
}
@keyframes driveOutTop {
  0% {
    -webkit-animation-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
    animation-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  30% {
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    -webkit-transform: translate3d(0, 30px, 0);
    transform: translate3d(0, 30px, 0);
  }
  60% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    -webkit-animation-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
    animation-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
    -webkit-transform: translate3d(0, -400px, 0);
    transform: translate3d(0, -400px, 0);
  }
}
.driveOutBottom {
  -webkit-animation-name: driveOutBottom;
  animation-name: driveOutBottom;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
}
@keyframes driveOutBottom {
  0% {
    -webkit-animation-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
    animation-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  30% {
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    -webkit-transform: translate3d(0, -30px, 0);
    transform: translate3d(0, -30px, 0);
  }
  60% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    -webkit-animation-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
    animation-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
    -webkit-transform: translate3d(0, 300px, 0);
    transform: translate3d(0, 300px, 0);
  }
}
.spin {
  -webkit-animation-name: spin;
  animation-name: spin;
  -webkit-animation-duration: 1.2s;
  animation-duration: 1.2s;
}
@keyframes spin {
  0% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: rotateZ(0deg);
    transform: rotateZ(0deg);
    -webkit-transform-origin: center center;
    transform-origin: center center;
  }
  20% {
    -webkit-animation-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
    animation-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
    -webkit-transform: rotateZ(-20deg);
    transform: rotateZ(-20deg);
    -webkit-transform-origin: center center;
    transform-origin: center center;
  }
  70% {
    -webkit-animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    -webkit-transform: rotateZ(380deg);
    transform: rotateZ(380deg);
    -webkit-transform-origin: center center;
    transform-origin: center center;
  }
  100% {
    -webkit-animation-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715);
    animation-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715);
    -webkit-transform: rotateZ(360deg);
    transform: rotateZ(360deg);
    -webkit-transform-origin: center center;
    transform-origin: center center;
  }
}
.spinIn {
  -webkit-animation-name: spinIn;
  animation-name: spinIn;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
}
@keyframes spinIn {
  0% {
    opacity: 0;
    -webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
    animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
    -webkit-transform: rotateZ(0deg) scale3d(0, 0, 0);
    transform: rotateZ(0deg) scale3d(0, 0, 0);
    -webkit-transform-origin: center center;
    transform-origin: center center;
  }
  50% {
    opacity: 1;
  }
  70% {
    -webkit-transform: rotateZ(380deg) scale3d(1, 1, 1);
    transform: rotateZ(380deg) scale3d(1, 1, 1);
    -webkit-transform-origin: center center;
    transform-origin: center center;
  }
  100% {
    -webkit-transform: rotateZ(360deg) scale3d(1, 1, 1);
    transform: rotateZ(360deg) scale3d(1, 1, 1);
    -webkit-transform-origin: center center;
    transform-origin: center center;
  }
}
.spinOut {
  -webkit-animation-name: spinOut;
  animation-name: spinOut;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
}
@keyframes spinOut {
  0% {
    -webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
    animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
    -webkit-transform: rotateZ(0deg) scale3d(1, 1, 1);
    transform: rotateZ(0deg) scale3d(1, 1, 1);
    -webkit-transform-origin: center center;
    transform-origin: center center;
  }
  20% {
    opacity: 1;
    -webkit-animation-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
    animation-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
    -webkit-transform: rotateZ(-20deg) scale3d(1, 1, 1);
    transform: rotateZ(-20deg) scale3d(1, 1, 1);
    -webkit-transform-origin: center center;
    transform-origin: center center;
  }
  30% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    -webkit-animation-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715);
    animation-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715);
    -webkit-transform: rotateZ(360deg) scale3d(0, 0, 0);
    transform: rotateZ(360deg) scale3d(0, 0, 0);
    -webkit-transform-origin: center center;
    transform-origin: center center;
  }
}
.swoopInLeft {
  -webkit-animation-name: swoopInLeft;
  animation-name: swoopInLeft;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
}
@keyframes swoopInLeft {
  0% {
    opacity: 0;
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transform: scaleX(1.5) translate3d(-400px, 0, 0);
    transform: scaleX(1.5) translate3d(-400px, 0, 0);
  }
  40% {
    opacity: 1;
    -webkit-animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    -webkit-transform: scaleX(1.2) translate3d(0, 0, 0);
    transform: scaleX(1.2) translate3d(0, 0, 0);
  }
  65% {
    -webkit-transform: scaleX(1) translate3d(20px, 0, 0);
    transform: scaleX(1) translate3d(20px, 0, 0);
  }
  100% {
    -webkit-animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    -webkit-transform: scaleX(1) translate3d(0, 0, 0);
    transform: scaleX(1) translate3d(0, 0, 0);
  }
}
.swoopInRight {
  -webkit-animation-name: swoopInRight;
  animation-name: swoopInRight;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
}
@keyframes swoopInRight {
  0% {
    opacity: 0;
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transform: scaleX(1.5) translate3d(400px, 0, 0);
    transform: scaleX(1.5) translate3d(400px, 0, 0);
  }
  40% {
    opacity: 1;
    -webkit-animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    -webkit-transform: scaleX(1.2) translate3d(0, 0, 0);
    transform: scaleX(1.2) translate3d(0, 0, 0);
  }
  65% {
    -webkit-transform: scaleX(1) translate3d(-20px, 0, 0);
    transform: scaleX(1) translate3d(-20px, 0, 0);
  }
  100% {
    -webkit-animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    -webkit-transform: scaleX(1) translate3d(0, 0, 0);
    transform: scaleX(1) translate3d(0, 0, 0);
  }
}
.swoopInTop {
  -webkit-animation-name: swoopInTop;
  animation-name: swoopInTop;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
}
@keyframes swoopInTop {
  0% {
    opacity: 0;
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transform: scaleY(1.5) translate3d(0, -400px, 0);
    transform: scaleY(1.5) translate3d(0, -400px, 0);
  }
  40% {
    opacity: 1;
    -webkit-animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    -webkit-transform: scaleY(1.2) translate3d(0, 0, 0);
    transform: scaleY(1.2) translate3d(0, 0, 0);
  }
  65% {
    -webkit-transform: scaleY(1) translate3d(0, 20px, 0);
    transform: scaleY(1) translate3d(0, 20px, 0);
  }
  100% {
    -webkit-animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    -webkit-transform: scaleY(1) translate3d(0, 0, 0);
    transform: scaleY(1) translate3d(0, 0, 0);
  }
}
.swoopInBottom {
  -webkit-animation-name: swoopInBottom;
  animation-name: swoopInBottom;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
}
@keyframes swoopInBottom {
  0% {
    opacity: 0;
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transform: scaleY(1.5) translate3d(0, 250px, 0);
    transform: scaleY(1.5) translate3d(0, 250px, 0);
  }
  40% {
    opacity: 1;
    -webkit-animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    -webkit-transform: scaleY(1.2) translate3d(0, 0, 0);
    transform: scaleY(1.2) translate3d(0, 0, 0);
  }
  65% {
    -webkit-transform: scaleY(1) translate3d(0, -20px, 0);
    transform: scaleY(1) translate3d(0, -20px, 0);
  }
  100% {
    -webkit-animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    -webkit-transform: scaleY(1) translate3d(0, 0, 0);
    transform: scaleY(1) translate3d(0, 0, 0);
  }
}
.swoopOutLeft {
  -webkit-animation-name: swoopOutLeft;
  animation-name: swoopOutLeft;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
}
@keyframes swoopOutLeft {
  0% {
    -webkit-animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    -webkit-transform: scaleX(1) translate3d(0, 0, 0);
    transform: scaleX(1) translate3d(0, 0, 0);
  }
  40% {
    opacity: 1;
    -webkit-transform: scaleX(1) translate3d(20px, 0, 0);
    transform: scaleX(1) translate3d(20px, 0, 0);
  }
  60% {
    -webkit-animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    -webkit-transform: scaleX(1.2) translate3d(0, 0, 0);
    transform: scaleX(1.2) translate3d(0, 0, 0);
  }
  100% {
    opacity: 0;
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transform: scaleX(1.5) translate3d(-400px, 0, 0);
    transform: scaleX(1.5) translate3d(-400px, 0, 0);
  }
}
.swoopOutRight {
  -webkit-animation-name: swoopOutRight;
  animation-name: swoopOutRight;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
}
@keyframes swoopOutRight {
  0% {
    -webkit-animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    -webkit-transform: scaleX(1) translate3d(0, 0, 0);
    transform: scaleX(1) translate3d(0, 0, 0);
  }
  40% {
    opacity: 1;
    -webkit-transform: scaleX(1) translate3d(-20px, 0, 0);
    transform: scaleX(1) translate3d(-20px, 0, 0);
  }
  60% {
    -webkit-animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    -webkit-transform: scaleX(1.2) translate3d(0, 0, 0);
    transform: scaleX(1.2) translate3d(0, 0, 0);
  }
  100% {
    opacity: 0;
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transform: scaleX(1.5) translate3d(400px, 0, 0);
    transform: scaleX(1.5) translate3d(400px, 0, 0);
  }
}
.swoopOutTop {
  -webkit-animation-name: swoopOutTop;
  animation-name: swoopOutTop;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
}
@keyframes swoopOutTop {
  0% {
    -webkit-animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    -webkit-transform: scaleY(1) translate3d(0, 0, 0);
    transform: scaleY(1) translate3d(0, 0, 0);
  }
  40% {
    opacity: 1;
    -webkit-transform: scaleY(1) translate3d(0, 20px, 0);
    transform: scaleY(1) translate3d(0, 20px, 0);
  }
  60% {
    -webkit-animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    -webkit-transform: scaleY(1.2) translate3d(0, 0, 0);
    transform: scaleY(1.2) translate3d(0, 0, 0);
  }
  100% {
    opacity: 0;
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transform: scaleY(1.5) translate3d(0, -400px, 0);
    transform: scaleY(1.5) translate3d(0, -400px, 0);
  }
}
.swoopOutBottom {
  -webkit-animation-name: swoopOutBottom;
  animation-name: swoopOutBottom;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
}
@keyframes swoopOutBottom {
  0% {
    -webkit-animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    -webkit-transform: scaleY(1) translate3d(0, 0, 0);
    transform: scaleY(1) translate3d(0, 0, 0);
  }
  40% {
    opacity: 1;
    -webkit-transform: scaleY(1) translate3d(0, -20px, 0);
    transform: scaleY(1) translate3d(0, -20px, 0);
  }
  60% {
    -webkit-animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    -webkit-transform: scaleY(1.2) translate3d(0, 0, 0);
    transform: scaleY(1.2) translate3d(0, 0, 0);
  }
  100% {
    opacity: 0;
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transform: scaleY(1.5) translate3d(0, 200px, 0);
    transform: scaleY(1.5) translate3d(0, 200px, 0);
  }
}
.fold {
  -webkit-animation-name: fold;
  animation-name: fold;
}
@keyframes fold {
  0% {
    -webkit-animation-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
    animation-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  30% {
    -webkit-animation-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
    animation-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
    -webkit-transform: scale3d(1, 0.4, 1);
    transform: scale3d(1, 0.4, 1);
  }
  60% {
    opacity: 1;
    -webkit-animation-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
    animation-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
    -webkit-transform: scale3d(0.4, 0.4, 1);
    transform: scale3d(0.4, 0.4, 1);
  }
  100% {
    opacity: 0;
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
    animation-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
    -webkit-transform: scale3d(0.2, 0.2, 0.2);
    transform: scale3d(0.2, 0.2, 0.2);
  }
}
.unfold {
  -webkit-animation-name: unfold;
  animation-name: unfold;
}
@keyframes unfold {
  0% {
    opacity: 0;
    -webkit-animation-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
    animation-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
    -webkit-transform: scale3d(0, 0, 0);
    transform: scale3d(0, 0, 0);
  }
  30% {
    opacity: 1;
    -webkit-animation-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
    animation-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
    -webkit-transform: scale3d(0.4, 0.4, 1);
    transform: scale3d(0.4, 0.4, 1);
  }
  60% {
    -webkit-animation-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
    animation-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
    -webkit-transform: scale3d(0.4, 1, 1);
    transform: scale3d(0.4, 1, 1);
  }
  100% {
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
    animation-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
.delay-100 {
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
}
.delay-150 {
  -webkit-animation-delay: 150ms;
  animation-delay: 150ms;
}
.duration-100 {
  -webkit-animation-duration: 0.1s;
  animation-duration: 0.1s;
}
.duration-150 {
  -webkit-animation-duration: 150ms;
  animation-duration: 150ms;
}
.delay-200 {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}
.delay-250 {
  -webkit-animation-delay: 250ms;
  animation-delay: 250ms;
}
.duration-200 {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
}
.duration-250 {
  -webkit-animation-duration: 250ms;
  animation-duration: 250ms;
}
.delay-300 {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}
.delay-350 {
  -webkit-animation-delay: 350ms;
  animation-delay: 350ms;
}
.duration-300 {
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
}
.duration-350 {
  -webkit-animation-duration: 350ms;
  animation-duration: 350ms;
}
.delay-400 {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}
.delay-450 {
  -webkit-animation-delay: 450ms;
  animation-delay: 450ms;
}
.duration-400 {
  -webkit-animation-duration: 0.4s;
  animation-duration: 0.4s;
}
.duration-450 {
  -webkit-animation-duration: 450ms;
  animation-duration: 450ms;
}
.delay-500 {
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
}
.delay-550 {
  -webkit-animation-delay: 550ms;
  animation-delay: 550ms;
}
.duration-500 {
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
}
.duration-550 {
  -webkit-animation-duration: 550ms;
  animation-duration: 550ms;
}
.delay-600 {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}
.delay-650 {
  -webkit-animation-delay: 650ms;
  animation-delay: 650ms;
}
.duration-600 {
  -webkit-animation-duration: 0.6s;
  animation-duration: 0.6s;
}
.duration-650 {
  -webkit-animation-duration: 650ms;
  animation-duration: 650ms;
}
.delay-700 {
  -webkit-animation-delay: 0.7s;
  animation-delay: 0.7s;
}
.delay-750 {
  -webkit-animation-delay: 750ms;
  animation-delay: 750ms;
}
.duration-700 {
  -webkit-animation-duration: 0.7s;
  animation-duration: 0.7s;
}
.duration-750 {
  -webkit-animation-duration: 750ms;
  animation-duration: 750ms;
}
.delay-800 {
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
}
.delay-850 {
  -webkit-animation-delay: 850ms;
  animation-delay: 850ms;
}
.duration-800 {
  -webkit-animation-duration: 0.8s;
  animation-duration: 0.8s;
}
.duration-850 {
  -webkit-animation-duration: 850ms;
  animation-duration: 850ms;
}
.delay-900 {
  -webkit-animation-delay: 0.9s;
  animation-delay: 0.9s;
}
.delay-950 {
  -webkit-animation-delay: 950ms;
  animation-delay: 950ms;
}
.duration-900 {
  -webkit-animation-duration: 0.9s;
  animation-duration: 0.9s;
}
.duration-950 {
  -webkit-animation-duration: 950ms;
  animation-duration: 950ms;
}
.delay-1000 {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}
.delay-1250 {
  -webkit-animation-delay: 1250ms;
  animation-delay: 1250ms;
}
.delay-1500 {
  -webkit-animation-delay: 1.5s;
  animation-delay: 1.5s;
}
.delay-1750 {
  -webkit-animation-delay: 1750ms;
  animation-delay: 1750ms;
}
.duration-1000 {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
}
.duration-1250 {
  -webkit-animation-duration: 1250ms;
  animation-duration: 1250ms;
}
.duration-1500 {
  -webkit-animation-duration: 1.5s;
  animation-duration: 1.5s;
}
.duration-1750 {
  -webkit-animation-duration: 1750ms;
  animation-duration: 1750ms;
}
.delay-2000 {
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
}
.delay-2250 {
  -webkit-animation-delay: 2250ms;
  animation-delay: 2250ms;
}
.delay-2500 {
  -webkit-animation-delay: 2.5s;
  animation-delay: 2.5s;
}
.delay-2750 {
  -webkit-animation-delay: 2750ms;
  animation-delay: 2750ms;
}
.duration-2000 {
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
}
.duration-2250 {
  -webkit-animation-duration: 2250ms;
  animation-duration: 2250ms;
}
.duration-2500 {
  -webkit-animation-duration: 2.5s;
  animation-duration: 2.5s;
}
.duration-2750 {
  -webkit-animation-duration: 2750ms;
  animation-duration: 2750ms;
}
.delay-3000 {
  -webkit-animation-delay: 3s;
  animation-delay: 3s;
}
.delay-3250 {
  -webkit-animation-delay: 3250ms;
  animation-delay: 3250ms;
}
.delay-3500 {
  -webkit-animation-delay: 3.5s;
  animation-delay: 3.5s;
}
.delay-3750 {
  -webkit-animation-delay: 3750ms;
  animation-delay: 3750ms;
}
.duration-3000 {
  -webkit-animation-duration: 3s;
  animation-duration: 3s;
}
.duration-3250 {
  -webkit-animation-duration: 3250ms;
  animation-duration: 3250ms;
}
.duration-3500 {
  -webkit-animation-duration: 3.5s;
  animation-duration: 3.5s;
}
.duration-3750 {
  -webkit-animation-duration: 3750ms;
  animation-duration: 3750ms;
}
.delay-4000 {
  -webkit-animation-delay: 4s;
  animation-delay: 4s;
}
.delay-4250 {
  -webkit-animation-delay: 4250ms;
  animation-delay: 4250ms;
}
.delay-4500 {
  -webkit-animation-delay: 4.5s;
  animation-delay: 4.5s;
}
.delay-4750 {
  -webkit-animation-delay: 4750ms;
  animation-delay: 4750ms;
}
.duration-4000 {
  -webkit-animation-duration: 4s;
  animation-duration: 4s;
}
.duration-4250 {
  -webkit-animation-duration: 4250ms;
  animation-duration: 4250ms;
}
.duration-4500 {
  -webkit-animation-duration: 4.5s;
  animation-duration: 4.5s;
}
.duration-4750 {
  -webkit-animation-duration: 4750ms;
  animation-duration: 4750ms;
}
.delay-5000 {
  -webkit-animation-delay: 5s;
  animation-delay: 5s;
}
.delay-5250 {
  -webkit-animation-delay: 5250ms;
  animation-delay: 5250ms;
}
.delay-5500 {
  -webkit-animation-delay: 5.5s;
  animation-delay: 5.5s;
}
.delay-5750 {
  -webkit-animation-delay: 5750ms;
  animation-delay: 5750ms;
}
.duration-5000 {
  -webkit-animation-duration: 5s;
  animation-duration: 5s;
}
.duration-5250 {
  -webkit-animation-duration: 5250ms;
  animation-duration: 5250ms;
}
.duration-5500 {
  -webkit-animation-duration: 5.5s;
  animation-duration: 5.5s;
}
.duration-5750 {
  -webkit-animation-duration: 5750ms;
  animation-duration: 5750ms;
}
.duration-6000 {
  -webkit-animation-duration: 6s;
  animation-duration: 6s;
}
.duration-6250 {
  -webkit-animation-duration: 6250ms;
  animation-duration: 6250ms;
}
.duration-6500 {
  -webkit-animation-duration: 6.5s;
  animation-duration: 6.5s;
}
.duration-6750 {
  -webkit-animation-duration: 6750ms;
  animation-duration: 6750ms;
}
.duration-7000 {
  -webkit-animation-duration: 7s;
  animation-duration: 7s;
}
.duration-7250 {
  -webkit-animation-duration: 7250ms;
  animation-duration: 7250ms;
}
.duration-7500 {
  -webkit-animation-duration: 7.5s;
  animation-duration: 7.5s;
}
.duration-7750 {
  -webkit-animation-duration: 7750ms;
  animation-duration: 7750ms;
}
.duration-8000 {
  -webkit-animation-duration: 8s;
  animation-duration: 8s;
}
.duration-8250 {
  -webkit-animation-duration: 8250ms;
  animation-duration: 8250ms;
}
.duration-8500 {
  -webkit-animation-duration: 8.5s;
  animation-duration: 8.5s;
}
.duration-8750 {
  -webkit-animation-duration: 8750ms;
  animation-duration: 8750ms;
}
.duration-9000 {
  -webkit-animation-duration: 9s;
  animation-duration: 9s;
}
.duration-9250 {
  -webkit-animation-duration: 9250ms;
  animation-duration: 9250ms;
}
.duration-9500 {
  -webkit-animation-duration: 9.5s;
  animation-duration: 9.5s;
}
.duration-9750 {
  -webkit-animation-duration: 9750ms;
  animation-duration: 9750ms;
}
.duration-10000 {
  -webkit-animation-duration: 10s;
  animation-duration: 10s;
}
.duration-10250 {
  -webkit-animation-duration: 10250ms;
  animation-duration: 10250ms;
}
.duration-10500 {
  -webkit-animation-duration: 10.5s;
  animation-duration: 10.5s;
}
.duration-10750 {
  -webkit-animation-duration: 10750ms;
  animation-duration: 10750ms;
}
