body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.roboto-font {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  text-transform: capitalize;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/* width */
::-webkit-scrollbar {
  width: 0px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 5px grey;  */
  border-radius: 5px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(206, 206, 206); 
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(36, 36, 36); 
}
.MuiTable-root {
  border-collapse: unset !important;
}

* {
  border-collapse: unset !important;
}

html {
  border-collapse: unset !important;
}

.MuiTableHead-root {
  display: block !important;
}

.MuiTableRow-root {
  display: block !important;
}

.MuiTableBody-root {
  display: block !important;
}

.MuiTableFooter-root {
  display: block !important;
}

.custom_material_ui_header header {
  position: relative !important;
}

.custom_material_ui_header .MuiDrawer-paper {
  position: relative !important;
}

.custom_material_ui_drawer_body {
  display: flex !important;
}

.custom_material_ui_drawer_body .custom-material-ui-component-MuiDrawer-paper {
  display: flex !important;
  position: relative !important;
}